import {make} from 'vuex-pathify'
import utils from './utils';

const NikitaSdk = require('@myfamilysrl/nikita_sdk');
const BcSyncSdk = require('@myfamilysrl/bcsync_sdk');
const IamSdk = require('@myfamilysrl/iam_sdk');

// Data
const state = {
  name: null,
  default: null,
  defaultVatRateId: null,
  defaultLocale: 'en-US',
  defaultTraitName: 'Customer',
  defaultFamily: 1,
  tenantUuid: '',
  tenantCollection: [],
  dateFormatting: 'YYYY-MM-DD',
  prefixValueSelector: 'accountingCur',
  currencySymbol: '',
  showMetodoExport: false,
  showPaymentMethods: false,
  showIbanSelector: false,
  showWarmholeFab: false,
  showFiscalProfile: false,
  showFamilies: false,
  showIbanSDI: false,
  showB2BCredentialsButton: false,
  showTNT: false,
  applyTaxesFromTaxJar: false,
  showMarketingData: true,
  showContractualData: true,
  showSubAgent: false,
  userDefaultParameters: {},
  ownershipDefaultParameters: {},
  customerDefaultParameters2Check: [],
  geonameAdmin1Description: "",
  geonameAdmin2Description: "",
  geographicalSalesTaxes: false,
  taxLiable: false,
  monitoringSuffix: '',
}

const mutations = make.mutations(state)

const actions = {
  
  ...make.actions(state),

  getInfo: async ({dispatch}, coll) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status == 200) {
          dispatch('setTenantCollection', Object.keys(JSON.parse(xhr.response)).map(k => ({
            tenantUuid: k, ...JSON.parse(xhr.response)[k]
          })).filter(el => coll.includes(el.tenantUuid)));
          res(JSON.parse(xhr.response));
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          rej(error);
        }
      }
    };
    xhr.open("GET", `https://myfamily.network/.well-known/tenants-configuration`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Cache-Control', 'max-age=0');
    xhr.send();
  }),

  setInfoWithTenantConfiguration: ({ dispatch }, tenantConfiguration) => {
    const defaultLocale = tenantConfiguration.default_locale ?? 'en-US';
    dispatch('setName', tenantConfiguration.name);
    dispatch('setDateFormatting', tenantConfiguration.date_formatting);
    dispatch('setPrefixValueSelector', tenantConfiguration.prefix_value_selector);
    dispatch('setCurrencySymbol', tenantConfiguration.currency_symbol);
    dispatch('setShowMetodoExport', tenantConfiguration.show_metodo_export);
    //! checked 20230508
    dispatch('setShowPaymentMethods', tenantConfiguration.show_payment_methods);
    dispatch('setShowIbanSelector', tenantConfiguration.show_iban_selector);
    dispatch('setShowWarmholeFab', tenantConfiguration.show_warmhole_fab);
    dispatch('setDefaultLocale', defaultLocale);
    dispatch('setLocale', defaultLocale);
    dispatch('setGeonameAdmin1Description', tenantConfiguration.geoname_admin_1_description);
    dispatch('setGeonameAdmin2Description', tenantConfiguration.geoname_admin_2_description);
    //! checked 20230508
    dispatch('setGeographicalSalesTaxes', tenantConfiguration.geographical_sales_taxes);
    dispatch('orders/apiOrderCanIWIPGet', true, {root: true});
    NikitaSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = defaultLocale;
    BcSyncSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = defaultLocale;
    IamSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = defaultLocale;
  },

  setTenantBasedInfo: ({ dispatch }, tenantUuid) => {
    let lang = localStorage.getItem('lang');
    switch (tenantUuid) {
      //! EUROPA
      case "7cd51b43-7661-4612-9207-a7d70d4de492":
/*        dispatch('setCustomerDefaultParameters2Check', [
          { step: 2, param: "email", text: "Email" }, 
          { step: 2, param: "defaultVatRate", text: "Default Vat Rate" }, 
          { step: 2, param: "defaultPaymentTermId", text: "Default Payment Term" }, 
          { step: 2, param: "businessName", text: "Business Name" }, 
          { step: 2, param: "vatNumber", text: "VAT number" }, 
          { step: 2, param: "country", text: "Country" } 
        ]);*/
        dispatch('setCustomerDefaultParameters2Check', [
          { step: 2, param: "email", text: "Email" },
          { step: 2, param: "businessName", text: "Business Name" },
          { step: 2, param: "country", text: "Country" }
        ]);
        dispatch('setShowSubAgent', true);
        dispatch('setShowFiscalProfile', true);
        dispatch('setShowFamilies', true);
        dispatch('setShowIbanSDI', true);
        dispatch('setMonitoringSuffix', 'AC');
        dispatch('setShowTNT', true);
        if(!lang) localStorage.setItem('lang', 'en-US');
        break;
      //! AMERICA
      case "ef2db024-8733-4d1f-8528-0b6b6b85c8ee":
        NikitaSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = 'en-US';
        BcSyncSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = 'en-US';
        IamSdk.ApiClient.instance.defaultHeaders["MF-LOCALE"] = 'en-US';
        dispatch('setDefaultTraitName', 'USA Customer');
        dispatch('setShowB2BCredentialsButton', true);
        dispatch('setApplyTaxesFromTaxJar', true);
        dispatch('setShowMarketingData', false);
        dispatch('setShowContractualData', false);
        dispatch('setUserDefaultParameters', { businessTypeId: 3, fiscalProfileId: 20, customerPurchaseCategoryId: 55 });
        dispatch('setOwnershipDefaultParameters', { familyId: 1, agentId: 442, supervisorAgentId: 442, chiefAgentId: 442, commercialSpecialistId: 56 });
/*        dispatch('setCustomerDefaultParameters2Check', [
          { step: 2, param: "email", text: "Email" }, 
          { step: 2, param: "defaultVatRate", text: "Default Vat Rate" }, 
          { step: 2, param: "defaultPaymentTermId", text: "Default Payment Term" }, 
          { step: 2, param: "businessName", text: "Business Name" }, 
          // { step: 2, param: "businessTypeId", text: "Business Type" }, 
          { step: 2, param: "defaultPaymentMethodId", text: "Payment Method" } 
        ]);*/
        dispatch('setCustomerDefaultParameters2Check', [
          { step: 2, param: "email", text: "Email" },
          { step: 2, param: "businessName", text: "Business Name" },
          { step: 2, param: "country", text: "Country" }
        ]);
        dispatch('setDefault', false);
        dispatch('items/initItemCollection', true, {root: true});
        dispatch('setTaxLiable', true);
        dispatch('setMonitoringSuffix', 'DC');
        if(!lang) localStorage.setItem('lang', 'en-US');
        break;
      //! BRAZ
      case "2f7b0f22-932e-47ea-8677-33f3946b708e":
        dispatch('setDefaultTraitName', 'BR Customer');
        dispatch('setDefaultVatRateId', 712);
        dispatch('setCustomerDefaultParameters2Check', [ 
          { step: 2, param: "email", text: "Email" }, 
          { step: 2, param: "businessName", text: "Business Name" } ,
          { step: 2, param: "vatNumber", text: "VAT number" }, 
        ]);
        dispatch('setDefault', true);
        dispatch('setShowB2BCredentialsButton', true);
        dispatch('setTaxLiable', true);
        dispatch('setMonitoringSuffix', 'DC');
        if(!lang) localStorage.setItem('lang', 'pt-BR');
      default:
        dispatch('setCustomerDefaultParameters2Check', [ 
          { step: 2, param: "email", text: "Email" }, 
          { step: 2, param: "businessName", text: "Business Name" } ,
          { step: 2, param: "vatNumber", text: "VAT number" }, 
        ]);
        dispatch('setDefault', true);
    }
  },

  setAll: ({ dispatch }, tenantUuid) => {
    utils.actions.loadRes();
    localStorage.setItem('tenantUuid', tenantUuid);
    window.location.reload();
  },

  loadTenantConfiguration: async ({ dispatch }, tenantCollection) => new Promise((res, rej) => {
    if(!tenantCollection.includes(localStorage.getItem('tenantUuid'))) localStorage.removeItem('tenantUuid');
    actions.getInfo({ dispatch }, tenantCollection).then(response => {
      localStorage.setItem('tenantUuid', localStorage.getItem('tenantUuid') || tenantCollection[0]);
      const tenantUuid = localStorage.getItem('tenantUuid');
      const tenantsConfiguration = response;
      const tenantConfiguration = tenantsConfiguration[tenantUuid];
      dispatch('setInfoWithTenantConfiguration', tenantConfiguration);
      dispatch('setTenantBasedInfo', tenantUuid);
    })
  }),
  
  setLocale: ({ commit }, locale) => {
    window?.numeral?.locale(locale);
  },
}

const getters = {
  rules4customerDefaultParameters2Check: (state) => (customer) => state.customerDefaultParameters2Check
    .reduce((a, b) => { !b.length? a.push(()=>!customer[b.param]) : a.push(b.map(x => () => !customer[x.param])); return a;}, []),
  isValidRules4customerDefaultParameters2Check: (state) => (customer) => state.customerDefaultParameters2Check
    .reduce((a, b) => { !b.length? a.push(()=>!customer[b.param]) : a.push(b.map(x => ()=>!customer[x.param])); return a;}, [])
    .map((el) => !!el.length ? el.reduce((a, b) => { a = b(customer) && a; return a;}, true) : el(customer))
    .some((el) => el),
  printError4CustomerDefaultParameters2Check: (state) => (customer) => `Missing required parameters [${state.customerDefaultParameters2Check
    .reduce((a, b) => { !b.length? a.push({...b, missing: !customer[b.param]}) : a.push(b.map(x => ({...x, missing: !customer[x.param]}))); return a; }, [])
    .flat()
    .filter(el => el.missing)
    .map(el => el.text)
    .join(', ')
    }]. No updates will be saved! Please insert required params`,
  isRequired: (state) => (param) => !!state.customerDefaultParameters2Check
    .find(el => el.length > 0? el.find(x => x.param == param) : el.param == param),
    // .map((el) => !!el.length ? el.reduce((a, b) => { a = b(customer) && a; return a;}, true) : el(customer))
    // .some((el) => el),
  tenantCollectionDropdown: (state) => state.tenantCollection.map( item => ({text: `${item.name}`, value: item.tenantUuid })),
  tenantName: (state) => state.tenantCollection.find(el => el.tenantUuid == localStorage.getItem('tenantUuid'))?.name ?? '',
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
