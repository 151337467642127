// Pathify
import { make } from 'vuex-pathify';
import utils from './utils';
import {TimeZonesApi} from "@myfamilysrl/nikita_sdk";

const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const PodioSdk = require('@myfamilysrl/podio2nikita_sdk');
const CustomerApi = new MyfamilysrlNikitaSdk.CustomersApi();
const AnchorApi = new MyfamilysrlNikitaSdk.AnchorApi();
const BusinessTypeApi = new MyfamilysrlNikitaSdk.BusinessTypeApi();
const PurchaseCategoryApi = new MyfamilysrlNikitaSdk.CustomerPurchaseCategoryApi();
const NikitaTimeZonesApi = new MyfamilysrlNikitaSdk.NikitaTimeZonesApi();
const CustomerSyncApi = new PodioSdk.CustomerSyncApi();

const ChannelApi = new MyfamilysrlNikitaSdk.SalesChannelApi();
const SectorApi = new MyfamilysrlNikitaSdk.SalesSectorApi();

const FiscalProfileApi = new MyfamilysrlNikitaSdk.FiscalProfileApi();
const CustomerStatisticalTagApi = new MyfamilysrlNikitaSdk.CustomerStatisticalTagApi();
const CustomerStatisticalDataApi = new MyfamilysrlNikitaSdk.CustomerStatisticalDataApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

let initializing = {
    customerCollection: false,
    customerPotentialCollection: false,
    businessTypeCollection: false,
    channelCollection: false,
    sectorCollection: false,
    purchaseCategoryCollection: false,
    fiscalProfileCollection: false,
    bulkCustomersCollection: false,
    customerStatisticaTag: false,
    timeZones: false
}

// Data
const state = {
    allCustomers: [],
    customers: [],
    parents: [],
    potentialCustomers: [],
    channels: [],
    sectors: [],
    customerOwnership: [],
    customerLocations: [],
    tempOwnership: {},
    businessTypes: [],
    groupIdentifierCollection: [],
    purchaseCategories: [],
    fiscalProfiles: [],
    bulkCustomers: [],
    filters: {},
    showFilters: false,
    inUseApiCustomersIdAnchorsGet: false,
    budgetFilters: {},
    timeZones: []
}

// setTimeout(() => {
//   let temp = localStorage.getItem("NikitaCustomerFilters") ?? "{}";
//   if (!!temp) {
//     let storedFilters = JSON.parse(temp ?? "{}") ?? {};
//     Object.keys(storedFilters).forEach(k => {
//       state.filters[k] = storedFilters[k];
//     })
//     if(Object.keys(storedFilters).filter(x => !!storedFilters[x] && !!storedFilters[x].length).length){
//       state.showFilters = true;
//     }
//   }
// }, 100);

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    initCustomerCollection: async ({ dispatch }, force) => {
        if (!!initializing.customerCollection) return;

        if (state.customers.length == 0 || !!force) {
            initializing.customerCollection = true;
            if (Object.keys(state.filters).filter(k => !!state.filters[k])?.length > 0) {
                dispatch('applyFilters', state.filters)
            } else {
                dispatch('apiCustomersGet', { force });
            }
        }
    },

    initCustomerPotentialCollection: async ({ dispatch }, force) => {
        if (!!initializing.customerPotentialCollection) return;

        if (state.potentialCustomers.length == 0 || !!force) {
            initializing.customerPotentialCollection = true;
            dispatch('apiCustomersPotentialsGet', { force });
        }
    },

    initBusinessTypeCollection: async ({ dispatch }, force) => {
        if (!!initializing.businessTypeCollection) return;

        if (state.businessTypes.length == 0 || !!force) {
            initializing.businessTypeCollection = true;
            dispatch('apiBusinessTypeGet', force);
        }
    },

    initChannelCollection: async ({ dispatch }, force) => {
        if (!!initializing.initChannelCollection) return;

        if (state.channels.length == 0 || !!force) {
            initializing.channelCollection = true;
            dispatch('apiChannelGet', force);
        }
    },

    initSectorCollection: async ({ dispatch }, force) => {
        if (!!initializing.sectorCollection) return;

        if (state.sectors.length == 0 || !!force) {
            initializing.sectorCollection = true;
            dispatch('apiSectorGet', force);
        }
    },

    initPurchaseCategoryCollection: async ({ dispatch }, force) => {
        if (!!initializing.purchaseCategoryCollection) return;

        if (state.purchaseCategories.length == 0 || !!force) {
            initializing.purchaseCategoryCollection = true;
            dispatch('apiPurchaseCategoryGet', force);
        }
    },

    initFiscalProfileCollection: async ({ dispatch }, force) => {
        if (!!initializing.fiscalProfileCollection) return;

        if (state.fiscalProfiles.length == 0 || !!force) {
            initializing.fiscalProfileCollection = true;
            dispatch('apiFiscalProfileGet', force);
        }
    },

    initBulkCustomersCollection: async ({ dispatch }, force) => {
        if (!!initializing.bulkCustomersCollection) return;

        if (state.customers.length == 0 || !!force) {
            initializing.bulkCustomersCollection = true;
            dispatch('apiCustomersBulkCustomersGet', force);
        }
    },

    initTimeZonesCollection: async ({ dispatch }, force) => {
        if (!!initializing.timeZones) return;

        if (state.timeZones.length == 0 || !!force) {
            initializing.timeZones = true;
            dispatch('apiNikitaTimeZonesGet');
        }
    },

    // opts = { force: bool, fromFilter: bool }
    apiCustomersGet: async ({ dispatch }, opts = {}) => new Promise((res, rej) => {
        !!opts.force && utils.actions.loadRes();
        CustomerApi.apiCustomersGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setAllCustomers', result);
                
                if (!state.parents.length) dispatch('reduceParents', result);
                
/*               dispatch('setPotentialCustomers', result.filter(x => !!x.potential).sort((a, b) => b.id - a.id));*/
                if (!opts.fromFilter) {
                    dispatch('setCustomers', result);
                }
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.customerCollection = false;
                !!opts.force && utils.actions.unloadRes();
            });
    }),

    apiCustomersPotentialsGet: async ({ dispatch }, opts = {}) => new Promise((res, rej) => {
        !!opts.force && utils.actions.loadRes();
        CustomerApi.apiCustomersPotentialsGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setPotentialCustomers', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                !!opts.force && utils.actions.unloadRes();
            });
    }),

    apiCustomersIdChildrenStatusGet: async ({ dispatch }, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdChildrenStatusGet(id, { MF_TENANT_ID })
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersBulkCustomersGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        CustomerApi.apiCustomersBulkCustomersGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setBulkCustomers', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.bulkCustomersCollection = false;
                !!force && utils.actions.unloadRes();
            });
    }),

    apiBusinessTypeGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        BusinessTypeApi.apiBusinessTypeGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setBusinessTypes', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.businessTypeCollection = false;

                !!force && utils.actions.unloadRes();
            });
    }),

    apiPurchaseCategoryGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        PurchaseCategoryApi.apiCustomerPurchaseCategoryGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setPurchaseCategories', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.purchaseCategoryCollection = false;
                !!force && utils.actions.unloadRes();
            });
    }),

    apiFiscalProfileGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        FiscalProfileApi.apiFiscalProfileGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setFiscalProfiles', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.fiscalProfileCollection = false;
                !!force && utils.actions.unloadRes();
            });
    }),

    apiCustomersPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'customer': MyfamilysrlNikitaSdk.Customer.constructFromObject(payload),
            MF_TENANT_ID
        };
        opts.customer.code = opts.customer.code ?? '';
        opts.customer.description = opts.customer.description ?? '';
        // dispatch('checkCustomerProperties', opts.customer)
        // .then(() => {
        CustomerApi.apiCustomersPost(opts)
            .then((response) => {
                dispatch('apiCustomersGet');
                dispatch('app/showSuccess', 'completed', { root: true });
                res(response);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            // })
            .finally(() => {
                utils.actions.unloadRes();
            });

    }),

    apiCustomersIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'customer': MyfamilysrlNikitaSdk.Customer.constructFromObject(payload),
            MF_TENANT_ID
        };
        let stringId = `${payload.id}`;
        CustomerApi.apiCustomersIdPut(stringId, opts)
            .then(() => {
                dispatch('app/showSuccess', 'completed', { root: true });
                dispatch('apiCustomersGet');
                res();
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let stringId = `${id}`;
        CustomerApi.apiCustomersIdDelete(stringId, { MF_TENANT_ID })
            .then(() => {
                dispatch('app/showSuccess', 'completed', { root: true });
                dispatch('apiCustomersGet');
                res();
            })
            .catch((error) => {
                console.log("error : ", error);
                dispatch('app/showError', `${`${error.body || error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdOwnershipGet: async ({ dispatch }, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdOwnershipGet(customerId, { MF_TENANT_ID })
            .then((result) => {
                dispatch('setCustomerOwnership', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdOwnershipFamilyIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let customerId = !!payload.customerId ? `${payload.customerId}` : payload.customerId;
        let familyId = !!payload.familyId ? `${payload.familyId}` : 1;
        let opts = {
            "customerOwnershipRequestDto": MyfamilysrlNikitaSdk.CustomerOwnershipRequestDto.constructFromObject(payload.opts),
            MF_TENANT_ID
        };
        CustomerApi.apiCustomersIdOwnershipFamilyIdPut(customerId, familyId, opts)
            .then((result) => {
                dispatch('app/showSuccess', 'completed', { root: true });
                dispatch('setCustomerOwnership', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdLocationsGet: async ({ dispatch }, customerId) => new Promise((resolve, reject) => {
        if (!customerId) return;
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdLocationsGet(customerId, { MF_TENANT_ID })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error))
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdIbansGet: async ({ dispatch }, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdIbansGet(customerId, { MF_TENANT_ID })
            .then((response) => res(response))
            .catch((error) => rej(error))
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersExportMetodoPost: ({ dispatch }, filters) => new Promise((res, rej) => {
        utils.actions.loadRes();

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                res(xhr.response);
            }
        };
        xhr.open("POST", `${process.env.VUE_APP_BACKEND_BASEPATH}/api/Customers/export/metodo`, true);
        xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
        xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
        xhr.setRequestHeader('Accept', 'text/plain');
        xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
        xhr.responseType = 'arraybuffer';
        xhr.send(JSON.stringify(filters));
        utils.actions.unloadRes();
    }),

/*    applyFilters: async ({dispatch}, payload) => new Promise((res, rej) => {
        // localStorage.setItem("NikitaCustomerFilters", JSON.stringify(payload));
        utils.actions.loadRes();
        dispatch('setCustomers', []);
        let filtersObj = MyfamilysrlNikitaSdk.CustomerFilters.constructFromObject(payload);
        console.log("filtersObj : ", filtersObj);
        console.log(payload);
        let opts = {
            customerFilters: MyfamilysrlNikitaSdk.CustomerFilters.constructFromObject(payload),
            MF_TENANT_ID
        }
        CustomerApi.apiCustomersFilteredPost(opts).then((result) => {
            dispatch('setCustomers', result);
            res(result);
        }, (error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
            rej(error);
        }).finally(() => {
            if (!state.allCustomers.length) {
                dispatch("apiCustomersGet", { force: true, fromFilter: true })
            }
            initializing.customerCollection = false;
            utils.actions.unloadRes();
        });
    }),*/

    applyFilters: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        dispatch('setCustomers', []);
        let filtersObj = MyfamilysrlNikitaSdk.CustomerFilters.constructFromObject(payload);
        if (payload.geonameCountryCode) filtersObj.geonameCountryCode = payload.geonameCountryCode;
        if (payload.geonameRegionCode) filtersObj.geonameRegionCode = payload.geonameRegionCode;
        if (payload.geonameProvinceCode) filtersObj.geonameProvinceCode = payload.geonameProvinceCode;
        let opts = {
            customerFilters: filtersObj,
            MF_TENANT_ID
        };
        CustomerApi.apiCustomersFilteredPost(opts).then((result) => {
            dispatch('setCustomers', result);
            res(result);
        }, (error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
            rej(error);
        }).finally(() => {
            if (!state.allCustomers.length) {
                dispatch("apiCustomersGet", { force: true, fromFilter: true });
            }
            initializing.customerCollection = false;
            utils.actions.unloadRes();
        });
    }),

    apiCustomersIdPriceCatalogPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            customerPriceCatalogRequestDto: MyfamilysrlNikitaSdk.CustomerPriceCatalogRequestDto.constructFromObject(payload),
            MF_TENANT_ID
        }
        CustomerApi.apiCustomersIdPriceCatalogPut(payload.customerId, opts)
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdPriceCatalogPriceCatalogIdDelete: async ({ dispatch }, param) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdPriceCatalogPriceCatalogIdDelete(param.customerId, param.priceCatalogId, { MF_TENANT_ID }).then((result) => {
            res(result);
        }, (error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
            rej(error);
        }).finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiCustomersListExport: ({ dispatch }) => new Promise((res, rej) => {
        utils.actions.loadRes();
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                utils.actions.unloadRes();
                if (xhr.status === 200) {
                    res(xhr.response);
                } else {
                    const error = `${xhr.status} - ${xhr.statusText}`;
                    dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                    rej(error);
                }
            }
        };
        //! old export url
        // xhr.open("POST", `${process.env.VUE_APP_BACKEND_BASEPATH}/reporting/CustomersListExport`, true);
        xhr.open("POST", `${process.env.VUE_APP_REPORTING_BASEPATH}/api/CustomersListExport`, true);
        xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
        xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
        xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
        xhr.responseType = 'arraybuffer';
        // xhr.send(JSON.stringify({ customerIds: customerIds }));
        xhr.send(JSON.stringify(state.filters));
    }),

    // checkCustomerProperties: async ({dispatch}, customer) => new Promise((res, rej) => {
    //     if (!customer.fiscalProfileId) dispatch('app/showError', "Missing Fiscal Profile", {root: true}) && rej();
    //     if (!customer.businessTypeId) dispatch('app/showError', "Missing Business type", {root: true}) && rej();
    //     if (!customer.defaultVatRate) dispatch('app/showError', "Missing default VAT rate", {root: true}) && rej();
    //     if (!customer.defaultPaymentTermId) dispatch('app/showError', "Missing default Payment Term", {root: true}) && rej();
    //     if (!customer.fiscalCode && !customer.vatNumber) dispatch('app/showError', "Missing Fiscal Code or VAT Number", {root: true}) && rej();
    //     if (!customer.businessName) dispatch('app/showError', "Missing Business Name", {root: true}) && rej();
    //     res();
    // }),

    apiCustomerStatisticalTagGet: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        if (!!initializing.customerStatisticalTag) return;

        utils.actions.loadRes();
        initializing.customerStatisticalTag = true;
        // applicato il tenant in chiamata
        CustomerStatisticalTagApi.apiCustomerStatisticalTagGet({ MF_TENANT_ID })
            .then((result) => {
                result = result.filter(x => x.key == 'CustomerGroup');
                dispatch("setGroupIdentifierCollection", result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                initializing.customerStatisticalTag = false;
                utils.actions.unloadRes();
            });
    }),

    apiCustomerStatisticalDataCustomerTagsGet: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();

        CustomerStatisticalDataApi.apiCustomerStatisticalDataCustomerIdTagsGet(payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomerStatisticalDataCustomerIdTagsTagIdPut: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        if (!!payload.id && !!payload.tagId) {
            CustomerStatisticalDataApi.apiCustomerStatisticalDataCustomerIdTagsTagIdPut(payload.id, payload.tagId)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                    reject(error);
                })
                .finally(() => {
                    utils.actions.unloadRes();
                });
        } else {
            utils.actions.unloadRes();
        }
    }),

    //! tag
    apiCustomersIdTagsGet: ({ dispatch }, id) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdTagsGet(+id, { MF_TENANT_ID })
        .then((result) => {
            resolve(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
            reject(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiCustomersIdTagsTagIdPut: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        let id = payload.id,
            tagId = payload.tagId,
            opts = {
                Tag: MyfamilysrlNikitaSdk.Tag.constructFromObject(payload),
                MF_TENANT_ID
            };
        CustomerApi.apiCustomersIdTagsTagIdPut(id, tagId, opts)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),
    apiCustomersIdTagsTagIdDelete: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        let id = payload.id,
            tagId = payload.tagId;
        CustomerApi.apiCustomersIdTagsTagIdDelete(+id, +tagId, { MF_TENANT_ID })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    // norepeat
    apiCustomersIdAnchorsGet: ({ dispatch }, id) => new Promise((resolve, reject) => {
        if(!!state.inUseApiCustomersIdAnchorsGet) {
            console.log("skipped inUseApiCustomersIdAnchorsGet");
        } else {
            state.inUseApiCustomersIdAnchorsGet = true;
            utils.actions.loadRes();
            CustomerApi.apiCustomersIdAnchorsGet(+id, { MF_TENANT_ID })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                state.inUseApiCustomersIdAnchorsGet = false;
                utils.actions.unloadRes();
            });
        }
    }),


    apiCustomersIdAddAnchorPost: ({ dispatch }, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        let opts = {
            anchorDto: MyfamilysrlNikitaSdk.Anchor.constructFromObject(payload),
            MF_TENANT_ID
        };
        CustomerApi.apiCustomersIdAddAnchorPost(payload.entityId, opts)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiAnchorIdIsDonePut: ({ dispatch }, id) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        AnchorApi.apiAnchorIdIsDonePut(+id, { MF_TENANT_ID })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiChannelGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        ChannelApi.apiSalesChannelGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setChannels', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.channelCollection = false;
                !!force && utils.actions.unloadRes();
            });
    }),

    apiSectorGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        SectorApi.apiSalesSectorGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setSectors', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.sectorCollection = false;
                !!force && utils.actions.unloadRes();
            });
    }),


    apiCustomerSyncIdPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            podioToCustomerFilters: payload,
            MF_TENANT_ID
        };
        CustomerSyncApi.apiCustomerSyncIdPost(payload.id, opts)
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiCustomersIdToPrestashopB2BPost: async ({ dispatch }, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdToPrestashopB2BPost(customerId, { MF_TENANT_ID })
            .then((result) => {
                dispatch('app/showSuccess', `Completed'}`, { root: true });
                res(result);
            })
            .catch(error => {
                dispatch('app/showError', `${`${error.body || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });

    }),


    apiCustomersIdDuplicatePost: ({ dispatch }, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerApi.apiCustomersIdDuplicatePost(id, { MF_TENANT_ID })
            .then((response) => {
                res(response);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiNikitaTimeZonesGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        NikitaTimeZonesApi.apiNikitaTimeZonesGet()
            .then((result) => {
                dispatch('setTimeZones', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.timeZones = false;
                !!force && utils.actions.unloadRes();
            });
    }),

    apiNikitaTimeZonesByCountryCountryCodeGet: async ({ dispatch }, countryCode) => new Promise((res, rej) => {
        utils.actions.loadRes();
        NikitaTimeZonesApi.apiNikitaTimeZonesByCountryCountryCodeGet(countryCode)
            .then((result) => {
                dispatch('setTimeZones', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                initializing.timeZones = false;
                utils.actions.unloadRes();
            });
    }),

    reduceParents: ({dispatch}, collection) => {
        utils.actions.loadRes();
        collection = collection.filter(x => !x.potential);
        let wP = collection.filter(x => !!x.parentCode),
            pP = collection.filter(x => !x.parentCode);
        dispatch('setParents', pP.reduce((a, b) => {
            a = a || [];
            if(wP.find(x => x.parentCode == b.code)) a.push(b);
            return a;
        }, []));
        utils.actions.unloadRes();
    },


}

const getters = {
    dropdownCustomers: state => state.customers
        .map(item => ({ text: item.code + " - " + item.businessName, value: item.id })),
    dropdownParents: state => state.customers
        .map(item => ({ text: item.code + " - " + item.businessName, value: item.code })),
    dropdownFilteredParents: state => state.parents
        .map(item => ({ text: item.code + " - " + item.businessName, value: item.code })),
    dropdownAllCustomers: state => state.allCustomers
        .map(item => ({ text: `${item.active ? '' : '[INACTIVE] '}${item.code} - ${item.businessName}`, value: item.id })),
    dropdownCustomersWithoutPotential: state => state.allCustomers
        .filter(el => !el.potential)
        .map(item => ({ text: `${item.active ? '' : '[INACTIVE] '}${item.code} - ${item.businessName}`, value: item.id })),
    dropdownAllBulkCustomers: state => state.bulkCustomers
        .map(item => ({ text: `${item.code} - ${item.businessName}${!!item.shopName? ` (${item.shopName})` : ``}`, value: item.id })),
    dropdownAllCustomersCode: state => state.allCustomers
        .map(item => ({ text: item.code + " - " + item.businessName, value: item.code })),
    dropdownAllBulkCustomersCode: state => state.bulkCustomers
        .map(item => ({ text: `${item.code} - ${item.businessName}${!!item.shopName? ` (${item.shopName})` : ``}`, value: item.code })),
    dropdownBusinessTypes: state => state.businessTypes
        .map(item => ({ text: item.description, value: item.id })),
    dropdownChannels: state => state.channels
        .map(item => ({ text: item.description, value: item.id })),
    dropdownSectors: state => state.sectors
        .map(item => ({ text: item.description, value: item.id })),
    businessTypesDropdown: state => state.businessTypes
        .map(item => ({ text: item.description, value: item.id })),
    customerPurchaseCategoriesDropdown: state => state.purchaseCategories
        .map(item => ({ text: item.description, value: item.id })),
    dropdownGroupIdentifiers: state => [...state.groupIdentifierCollection
        .map(item => ({ text: item.value, value: item.id }))],
    customerPurchaseCategoriesDropdownFiltered: state => (customerType) => state.purchaseCategories
        .map(item => ({ text: item.description, value: item.id }))
        .filter(el => !!customerType && el.text.includes(customerType.toLowerCase())),
    formattedName: state => (id) => {
        let user = state.bulkCustomers.find(el => el.id == id);
        return !!user ? `${user.code} - ${user?.businessName}` : '-';
    },
    formattedNameByCode: state => (code = '') => {
        let user = state.bulkCustomers.find(el => el.code == code);
        return !!user ? `${user.code} - ${user?.businessName}` : '-';
    },
    customerInfo: state => (id) => {
        let c = state.allCustomers.find(el => el.id == id);
        return `${c?.code ?? '-'} - ${c?.businessName ?? '-'}`;
    },
    dropdownTimezones: state => state.timeZones
        .map(item => ({ text: `${[item.abbreviationSDT,item.abbreviationDST].filter(x => !!x).join('/')} (${item.tzIdentifier})`, value: item.id }))

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
