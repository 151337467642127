// Pathify
import { dispatch, make } from 'vuex-pathify';
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const AgentApi = new MyfamilysrlNikitaSdk.AgentApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
  agents: [],
  loadingAgents: false
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initAgentCollection: async (dispatch, force) => new Promise((res, rej) => {
    if (state.agents.length == 0 || !!force) {
      actions.apiAgentGet(dispatch, force)
          .then((response) => res(response))
          .catch((error) => rej(error))
    } else {
      res(state.agents);
    }
  }),

  apiAgentGet: async ({ dispatch }, force = false) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    dispatch("setLoadingAgents", true);
    AgentApi.apiAgentGet({ MF_TENANT_ID })
    .then((result) => {
      dispatch('setAgents', result);
      dispatch("setLoadingAgents", false);
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej();
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  apiAgentPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'agent': MyfamilysrlNikitaSdk.Agent.constructFromObject(payload),
      MF_TENANT_ID 
    };
    opts.agent.code = opts.agent.code ?? 'UPDATE_ME';
    AgentApi.apiAgentPost(opts)
    .then((result) => {
      dispatch('apiAgentGet');
      dispatch('app/showSuccess', 'completed', { root: true });
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiAgentIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'agent': MyfamilysrlNikitaSdk.Agent.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    AgentApi.apiAgentIdPut(stringId, opts)
    .then((result) => {
      dispatch('apiAgentGet')
      dispatch('app/showSuccess', 'completed', { root: true });
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiAgentIdSyncOwnershipsToPrestashopB2BPatch: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    AgentApi.apiAgentIdSyncOwnershipsToPrestashopB2BPatch(id, { MF_TENANT_ID })
        .then((result) => {
          dispatch('app/showSuccess', `Completed`, { root: true });
          res(result);
        })
        .catch(error => {
          dispatch('app/showError', `${`${error.body || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });

  }),

  apiAgentIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    AgentApi.apiAgentIdDelete(stringId, { MF_TENANT_ID })
    .then(() => {
      dispatch('apiAgentGet')
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownActiveAgents: state => {
    return state.agents?.filter(item => !!item.active).map(item => ({ text: item.code + " - " + item.name, value: item.id }));
  },
  dropdownAgents: state => {
    return state.agents?.map(item => ({ text: item.code + " - " + item.name, value: item.id }));
  },
  getAgentShortName: state => (agentId) => state.agents?.find(el => el.id == agentId)?.name?.split(' ')[0],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
